import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

import GuestGuard from "./components/Auth/GuestGuard";
import AuthGuard from "./components/Auth/AuthGuard";

import { BASE_URL } from "./config/constant";
import WebsiteFrontend from "./layouts/WebsiteFrontend/App";
import ScrollToTop from './components/WebsiteFrontend/ScrollToTop';


export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
              <ScrollToTop />
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/app/auth/signin-1',
    component: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    exact: true,
    path: '/app/404',
    component: lazy(() => import('./views/errors/NotFound404'))
  },
  {
    exact: true,
    path: '/app/maintenance/coming-soon',
    component: lazy(() => import('./views/maintenance/ComingSoon'))
  },
  {
    exact: true,
    path: '/app/maintenance/error',
    component: lazy(() => import('./views/maintenance/Error'))
  },
  {
    exact: true,
    path: '/app/maintenance/offline-ui',
    component: lazy(() => import('./views/maintenance/OfflineUI'))
  },
  {
    exact: true,
    path: '/app/auth/signup-1',
    component: lazy(() => import('./views/auth/signup/SignUp1')),
  },
  {
    exact: true,
    path: '/app/auth/signup-2',
    component: lazy(() => import('./views/auth/signup/SignUp2'))
  },
  {
    exact: true,
    path: '/app/auth/signup-3',
    component: lazy(() => import('./views/auth/signup/SignUp3'))
  },
  {
    exact: true,
    path: '/app/auth/signup-4',
    component: lazy(() => import('./views/auth/signup/SignUp4'))
  },
  {
    exact: true,
    path: '/app/auth/signup-5',
    component: lazy(() => import('./views/auth/signup/SignUp5'))
  },
  {
    exact: true,
    path: '/app/auth/signin-2',
    component: lazy(() => import('./views/auth/signin/SignIn2'))
  },
  {
    exact: true,
    path: '/app/auth/signin-3',
    component: lazy(() => import('./views/auth/signin/SignIn3'))
  },
  {
    exact: true,
    path: '/app/auth/signin-4',
    component: lazy(() => import('./views/auth/signin/SignIn4'))
  },
  {
    exact: true,
    path: '/app/auth/signin-5',
    component: lazy(() => import('./views/auth/signin/SignIn5'))
  },
  {
    exact: true,
    path: '/app/auth/reset-password-1',
    component: lazy(() => import('./views/auth/reset-password/ResetPassword1'))
  },
  {
    exact: true,
    path: '/app/auth/reset-password-2',
    component: lazy(() => import('./views/auth/reset-password/ResetPassword2'))
  },
  {
    exact: true,
    path: '/app/auth/reset-password-3',
    component: lazy(() => import('./views/auth/reset-password/ResetPassword3'))
  },
  {
    exact: true,
    path: '/app/auth/reset-password-4',
    component: lazy(() => import('./views/auth/reset-password/ResetPassword4'))
  },
  {
    exact: true,
    path: '/app/auth/reset-password-5',
    component: lazy(() => import('./views/auth/reset-password/ResetPassword5'))
  },
  {
    exact: true,
    path: '/app/auth/change-password',
    component: lazy(() => import('./views/auth/ChangePassword'))
  },
  {
    exact: true,
    path: '/app/auth/profile-settings',
    component: lazy(() => import('./views/auth/ProfileSettings'))
  },
  {
    exact: true,
    path: '/app/auth/tabs-auth',
    component: lazy(() => import('./views/auth/TabsAuth'))
  },
  {
    exact: true,
    path: '/app/auth/map-form',
    component: lazy(() => import('./views/auth/MapForm'))
  },
  {
    exact: true,
    path: '/app/auth/subscribe',
    component: lazy(() => import('./views/auth/Subscribe'))
  },
  {
    exact: true,
    path: '/app/landing',
    component: lazy(() => import('./views/landing'))
  },

  {
    path: '/app',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      /** website admin routes start */
      /** home */
        {
          exact: true,
          path: '/app/website-admin/homes',
          component: lazy(() => import('./views/website-admin/homes/AddListHome')),
        },

        /** banner */
          /** edit */
            {
              exact: true,
              path: '/app/website-admin/home/banner/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/homes/homeBanner/EditHomeBanner')),
            },

        /** service */
          /** edit */
            {
              exact: true,
              path: '/app/website-admin/home/service/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/homes/homeService/EditHomeService')),
            },

        /** product */
          /** edit */
            {
              exact: true,
              path: '/app/website-admin/home/product/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/homes/homeProduct/EditHomeProduct')),
            },

        /** award */
          /** edit */
            {
              exact: true,
              path: '/app/website-admin/home/award/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/homes/homeAward/EditHomeAward')),
            },

        /** blog */
          /** edit */
            {
              exact: true,
              path: '/app/website-admin/home/blog/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/homes/homeBlog/EditHomeBlog')),
            },

        /** client */
          /** edit */
            {
              exact: true,
              path: '/app/website-admin/home/client/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/homes/homeClient/EditHomeClient')),
            },

        /** testimonial */
          /** edit */
            {
              exact: true,
              path: '/app/website-admin/home/testimonial/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/homes/homeTestimonial/EditHomeTestimonial')),
            },

      /** contact */
        {
          exact: true,
          path: '/app/website-admin/contacts',
          component: lazy(() => import('./views/website-admin/contacts/AddListContact')),
        },

        /** form  */
          {
            exact: true,
            path: '/app/website-admin/contact-form',
            component: lazy(() => import('./views/website-admin/contacts/FormList')),
          },

      /** career */
        {
          exact: true,
          path: '/app/website-admin/careers',
          component: lazy(() => import('./views/website-admin/careers/AddListCareer')),
        },   

        /** edit department */
          {
            exact: true,
            path: '/app/website-admin/job-listing/department/redirectToEdit/:id',
            component: lazy(() => import('./views/website-admin/careers/department/EditDepartment')),
          },

        /** edit job listing  */
          {
            exact: true,
            path: '/app/website-admin/job-listing/redirectToEdit/:id',
            component: lazy(() => import('./views/website-admin/careers/JobListing/EditJobListing')),
          },

          /** edit responsibility */
            {
              exact: true,
              path: '/app/website-admin/job-listing/:jobId/responsibility/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/careers/JobListing/jobResponsibility/EditJobResponsibility')),
            },

          /** edit required skill */
            {
              exact: true,
              path: '/app/website-admin/job-listing/:jobId/required-skill/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/careers/JobListing/jobRequiredSkill/EditJobRequiresSkill')),
            },

        /** resumes  */
          {
            exact: true,
            path: '/app/website-admin/resume',
            component: lazy(() => import('./views/website-admin/careers/resumes/resume')),
          },

        /** individual-position-resumes  */
          {
            exact: true,
            path: '/app/website-admin/individual-position-resume',
            component: lazy(() => import('./views/website-admin/careers/resumes/individual-position-resume')),
          },

      /** about */
      {
        exact: true,
        path: '/app/website-admin/abouts',
        component: lazy(() => import('./views/website-admin/abouts/AddListAbout')),
      },

      /** blog */
        /** banner  */
          {
            exact: true,
            path: '/app/website-admin/blogs/banner/add',
            component: lazy(() => import('./views/website-admin/blogs/banner/AddBanner')),
          },
        /** add  */
          {
            exact: true,
            path: '/app/website-admin/blogs/add',
            component: lazy(() => import('./views/website-admin/blogs/AddListBlog')),
          },

        /** edit */
          {
            exact: true,
            path: '/app/website-admin/blogs/redirectToEdit/:id',
            component: lazy(() => import('./views/website-admin/blogs/EditBlog')),
          },

          /** related blog */
            /** edit */
            {
              exact: true,
              path: '/app/website-admin/blogs/:blogId/relatedBlog/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/blogs/relatedBlog/EditRelatedBlog')),
            },

      /** News And Update add  */
        /** banner  */
          {
            exact: true,
            path: '/app/website-admin/news-and-updates/banner/add',
            component: lazy(() => import('./views/website-admin/news-and-updates/banner/AddBanner')),
          },

        /** add */
          {
            exact: true,
            path: '/app/website-admin/news-and-updates/add',
            component: lazy(() => import('./views/website-admin/news-and-updates/AddListNewsAndUpdate')),
          },

        /** edit */
          {
            exact: true,
            path: '/app/website-admin/news-and-updates/redirectToEdit/:id',
            component: lazy(() => import('./views/website-admin/news-and-updates/EditNewsAndUpdate')),
          },
          
          /** related news-and-updates */
            /** edit */
            {
              exact: true,
              path: '/app/website-admin/news-and-updates/:newsAndUpdateId/relatedNewsAndUpdate/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/news-and-updates/relatedNewsAndUpdate/EditRelateNewsAndUpdate')),
            },

      /** award */
      /** add */
      {
        exact: true,
        path: '/app/website-admin/awards/add',
        component: lazy(() => import('./views/website-admin/awards/AddListAward')),
      },

      /** edit */
      {
        exact: true,
        path: '/app/website-admin/awards/redirectToEdit/:id',
        component: lazy(() => import('./views/website-admin/awards/EditAward')),
      },

      /** client */
      /** add */
      {
        exact: true,
        path: '/app/website-admin/clients/add',
        component: lazy(() => import('./views/website-admin/clients/AddListClient')),
      },

      /** edit */
      {
        exact: true,
        path: '/app/website-admin/clients/redirectToEdit/:id',
        component: lazy(() => import('./views/website-admin/clients/EditClient')),
      },

      /** testimonial */
        /** add */
        {
          exact: true,
          path: '/app/website-admin/testimonials/add',
          component: lazy(() => import('./views/website-admin/testimonials/AddListTestimonial')),
        },

        /** edit */
        {
          exact: true,
          path: '/app/website-admin/testimonials/redirectToEdit/:id',
          component: lazy(() => import('./views/website-admin/testimonials/EditTestimonial')),
        },

        /** parallel image edit */
        {
          exact: true,
          path: '/app/website-admin/testimonials/parallelImage/redirectToEdit/:id',
          component: lazy(() => import('./views/website-admin/testimonials/EditParallelImage')),
        },

      /** team */
      /** add */
      {
        exact: true,
        path: '/app/website-admin/teams/add',
        component: lazy(() => import('./views/website-admin/teams/AddListTeam')),
      },

      /** edit */
      {
        exact: true,
        path: '/app/website-admin/teams/redirectToEdit/:id',
        component: lazy(() => import('./views/website-admin/teams/EditTeam')),
      },

      /** disclaimer  */
      {
        exact: true,
        path: '/app/website-admin/disclaimers',
        component: lazy(() => import('./views/website-admin/disclaimers/AddDisclaimer')),
      },

      /** term  */
      {
        exact: true,
        path: '/app/website-admin/terms',
        component: lazy(() => import('./views/website-admin/terms/AddTerm')),
      },

      /** privacy  */
      {
        exact: true,
        path: '/app/website-admin/privacy',
        component: lazy(() => import('./views/website-admin/privacy/AddPrivacy')),
      },

      /** faq  */
      /** add */
      {
        exact: true,
        path: '/app/website-admin/faqs/add',
        component: lazy(() => import('./views/website-admin/faqs/AddListFAQ')),
      },

      /** edit  */
      {
        exact: true,
        path: '/app/website-admin/faqs/redirectToEdit/:id',
        component: lazy(() => import('./views/website-admin/faqs/EditFAQ')),
      },

      /** projects  */
      /** add */
      {
        exact: true,
        path: '/app/website-admin/projects/add',
        component: lazy(() => import('./views/website-admin/projects/AddListProject')),
      },

      /** edit */
      {
        exact: true,
        path: '/app/website-admin/projects/redirectToEdit/:id',
        component: lazy(() => import('./views/website-admin/projects/EditProject')),
      },

      /** banner */
      {
        exact: true,
        path: '/app/website-admin/projects/banner/add',
        component: lazy(() => import('./views/website-admin/projects/banner/AddBanner')),
      },

      /** Category */
      /** add */
      {
        exact: true,
        path: '/app/website-admin/projects/category/add',
        component: lazy(() => import('./views/website-admin/projects/category/AddListCategory')),
      },

      /** edit */
      {
        exact: true,
        path: '/app/website-admin/projects/category/redirectToEdit/:id',
        component: lazy(() => import('./views/website-admin/projects/category/EditCategory')),
      },

      /** Gallery Image */

      /** edit */
      {
        exact: true,
        path: '/app/website-admin/projects/:projectId/galleryImage/redirectToEdit/:id',
        component: lazy(() => import('./views/website-admin/projects/galleryImage/EditGalleryImage')),
      },


      /** products  */
      /** banner */
      {
        exact: true,
        path: '/app/website-admin/products/banner/add',
        component: lazy(() => import('./views/website-admin/products/banner/AddBanner')),
      },

      /** Category */
      /** add */
      {
        exact: true,
        path: '/app/website-admin/products/category/add',
        component: lazy(() => import('./views/website-admin/products/category/AddListCategory')),
      },

      /** edit */
      {
        exact: true,
        path: '/app/website-admin/products/category/redirectToEdit/:id',
        component: lazy(() => import('./views/website-admin/products/category/EditCategory')),
      },

      /** Sub Category */
        /** add */
        {
          exact: true,
          path: '/app/website-admin/products/subCategory/add',
          component: lazy(() => import('./views/website-admin/products/subCategory/AddListSubCategory')),
        },

      /** edit */
      {
        exact: true,
        path: '/app/website-admin/products/subCategory/redirectToEdit/:id',
        component: lazy(() => import('./views/website-admin/products/subCategory/EditSubCategory')),
      },

      /** Detail */
        /** add */
          {
            exact: true,
            path: '/app/website-admin/products/detail/add',
            component: lazy(() => import('./views/website-admin/products/detail/AddListDetail')),
          },

        /** edit */
          {
            exact: true,
            path: '/app/website-admin/products/detail/redirectToEdit/:id',
            component: lazy(() => import('./views/website-admin/products/detail/EditDetail')),
          },

          /** specification edit */
            {
              exact: true,
              path: '/app/website-admin/products/:productId/specification/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/products/detail/specification/EditSpecification')),
            },

          /** similar product edit */
            {
              exact: true,
              path: '/app/website-admin/products/:productId/similarProduct/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/products/detail/similarProduct/EditSimilarProduct')),
            },

          /** customer review edit */
            {
              exact: true,
              path: '/app/website-admin/products/:productId/customerReview/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/products/detail/customerReview/EditCustomerReview')),
            },

          /** parallelImage edit */
            // {
            //   exact: true,
            //   path: '/app/website-admin/products/detail/parallelImage/redirectToEdit/:id',
            //   component: lazy(() => import('./views/website-admin/products/detail/EditCustomerReviewParallelImage')),
            // },

          /** other product edit */
            {
              exact: true,
              path: '/app/website-admin/products/:productId/otherProduct/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/products/detail/otherProduct/EditOtherProduct')),
            },
        
        /**  */

      /** services  */
        /** banner */
        {
          exact: true,
          path: '/app/website-admin/services/banner/add',
          component: lazy(() => import('./views/website-admin/services/banner/AddBanner')),
        },
        /** add */
        {
          exact: true,
          path: '/app/website-admin/services/add',
          component: lazy(() => import('./views/website-admin/services/AddListService')),
        },

        /** edit */
        {
          exact: true,
          path: '/app/website-admin/services/redirectToEdit/:id',
          component: lazy(() => import('./views/website-admin/services/EditService')),
        },
        /** advantage */
          /** edit */
          {
            exact: true,
            path: '/app/website-admin/services/:serviceId/advantage/redirectToEdit/:id',
            component: lazy(() => import('./views/website-admin/services/advantage/EditAdvantage')),
          },

        /** about service */
          /** edit */
          {
            exact: true,
            path: '/app/website-admin/services/:serviceId/aboutService/redirectToEdit/:id',
            component: lazy(() => import('./views/website-admin/services/aboutService/EditAboutService')),
          },

        /** service project */
          /** edit */
          {
            exact: true,
            path: '/app/website-admin/services/:serviceId/project/redirectToEdit/:id',
            component: lazy(() => import('./views/website-admin/services/project/EditProject')),
          },

      /** renolit  */
        {
          exact: true,
          path: '/app/website-admin/renolits/add',
          component: lazy(() => import('./views/website-admin/renolits/AddRenolit')),
        },

        /** category */
          /** add */
          {
            exact: true,
            path: '/app/website-admin/renolits/category/add',
            component: lazy(() => import('./views/website-admin/renolits/category/AddListCategory')),
          },

          /** edit */
          {
            exact: true,
            path: '/app/website-admin/renolits/category/redirectToEdit/:id',
            component: lazy(() => import('./views/website-admin/renolits/category/EditCategory')),
          },

          /** membrane texture */
            /** edit */
            {
              exact: true,
              path: '/app/website-admin/renolits/category/:categoryId/membraneTexture/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/renolits/category/membraneTexture/EditMembraneTexture')),
            },

            /** gallery image */
              /** edit */
              {
                exact: true,
                path: '/app/website-admin/renolits/category/:categoryId/membraneTexture/:textureId/galleryImage/redirectToEdit/:id',
                component: lazy(() => import('./views/website-admin/renolits/category/membraneTexture/galleryImage/EditGalleryImage')),
              },

          /** benefit */
            /** edit */
            {
              exact: true,
              path: '/app/website-admin/renolits/category/:categoryId/benefit/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/renolits/category/benefit/EditBenefit')),
            },

      /** setting */
        {
          exact: true,
          path: '/app/website-admin/settings',
          component: lazy(() => import('./views/website-admin/settings/AddSetting')),
        },

        /** service */
          /** edit */
            {
              exact: true,
              path: '/app/website-admin/setting/footer/service/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/settings/footerService/EditFooterService')),
            },

        /** project */
          /** edit */
            {
              exact: true,
              path: '/app/website-admin/setting/footer/project/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/settings/footerProject/EditFooterProject')),
            },

        /** product */
          /** edit */
            {
              exact: true,
              path: '/app/website-admin/setting/footer/product/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/settings/footerProduct/EditFooterProduct')),
            },
        
        /** enquiries  */
          {
            exact: true,
            path: '/app/website-admin/enquiry',
            component: lazy(() => import('./views/website-admin/enquiries/EnquiryList')),
          },
        
        /** why-swimwell  */
          /** banner */
          {
            exact: true,
            path: '/app/website-admin/why-swimwell',
            component: lazy(() => import('./views/website-admin/why-swimwell/AddBanner')),
          },

          /** Edit usp */
            {
              exact: true,
              path: '/app/website-admin/why-swimwell/usp/redirectToEdit/:id',
              component: lazy(() => import('./views/website-admin/why-swimwell/usp/EditUsp')),
            },
      /** website admin routes end */
      /** sales verification routes */
      {
        exact: true,
        path: '/app/verification/dashboard',
        component: lazy(() => import('./views/dashboard/VerificationDash'))
      },
      {
        exact: true,
        path: '/app/verification/add-lead',
        component: lazy(() => import('./views/verification/AddLead'))
      },
      {
        exact: true,
        path: '/app/verification/read-leads',
        component: lazy(() => import('./views/verification/ReadLeads'))
      },
      {
        exact: true,
        path: '/app/verification/edit-leads/:lead_id',
        component: lazy(() => import('./views/verification/EditLead'))
      },
      /**sales dashboard routes */
      {
        exact: true,
        path: '/app/sales/dashboard',
        component: lazy(() => import('./views/dashboard/SalesDash'))
      },
      {
        exact: true,
        path: '/app/sales/read-leads',
        component: lazy(() => import('./views/sales/Leads'))
      },
      {
        exact: true,
        path: '/app/sales/leads/:lead_id',
        component: lazy(() => import('./views/sales/Leads/Edit'))
      },
      {
        exact: true,
        path: '/app/sales/pre-quotation/lead',
        component: lazy(() => import('./views/sales/PQuotation'))
      },
      {
        exact: true,
        path: '/app/sales/pre-quotation/lead/:lead_id',
        component: lazy(() => import('./views/sales/PQuotation/PrepareQuotation'))
      },
      {
        exact: true,
        path: '/app/sales/read-quotation',
        component: lazy(() => import('./views/sales/DesignOfQuotaion/'))
      },
      {
        exact: true,
        path: '/app/sales/design/:lead_id',
        component: lazy(() => import('./views/sales/DesignOfQuotaion/MakeDesign'))
      },
      {
        exact: true,
        path: '/app/sales/extra-payment',
        component: lazy(() => import('./views/sales/ExtraPayment'))
      },
      {
        exact: true,
        path: '/app/sales/extra-payment/:lead_id',
        component: lazy(() => import('./views/sales/ExtraPayment/MadePayment'))
      },
      {
        exact: true,
        path: '/app/sales/prepare-terms',
        component: lazy(() => import('./views/sales/PrepareTerms'))
      },
      {
        exact: true,
        path: '/app/sales/prepare-terms/:lead_id',
        component: lazy(() => import('./views/sales/PrepareTerms/MadeTerms'))
      },
      {
        exact: true,
        path: '/app/sales/final-quatation',
        component: lazy(() => import('./views/sales/FinalQuotation'))
      },
      {
        exact: true,
        path: '/app/sales/quotation-history/:lead_id',
        component: lazy(() => import('./views/sales/FinalQuotation/ListQuotation'))
      },
      {
        exact: true,
        path: '/app/sales/quotation-history/view/:lead_id',
        component: lazy(() => import('./views/sales/FinalQuotation/ListQuotation/ViewQuotation'))
      },
      {
        exact: true,
        path: '/app/sales/checklist',
        component: lazy(() => import('./views/sales/CheckList'))
      },
      {
        exact: true,
        path: '/app/sales/checklist/:lead_id',
        component: lazy(() => import('./views/sales/CheckList/CheckListForm'))
      },
      {
        exact: true,
        path: '/app/sales/checklist/:lead_id/view',
        component: lazy(() => import('./views/sales/CheckList/ViewCheckList'))
      },
      /**hr dashboard routes */
      {
        exact: true,
        path: '/app/hr/dashboard',
        component: lazy(() => import('./views/dashboard/HumanResourceDash'))
      },
      {
        exact: true,
        path: '/app/hr/read-leaves',
        component: lazy(() => import('./views/human-resource/Leaves'))
      },
      {
        exact: true,
        path: '/app/hr/read-attandance',
        component: lazy(() => import('./views/human-resource/Attandance'))
      },
      {
        exact: true,
        path: '/app/hr/read-timesheet',
        component: lazy(() => import('./views/human-resource/Timesheet'))
      },
      {
        exact: true,
        path: '/app/hr/employee-goal',
        component: lazy(() => import('./views/human-resource/Appraisals/Goal'))
      },
      {
        exact: true,
        path: '/app/hr/employee-suggestions',
        component: lazy(() => import('./views/human-resource/Appraisals/Suggestion'))
      },
      {
        exact: true,
        path: '/app/hr/employee',
        component: lazy(() => import('./views/human-resource/Employee'))
      },
      {
        exact: true,
        path: '/app/hr/create-employee',
        component: lazy(() => import('./views/human-resource/CreateEmployee'))
      },
      {
        exact: true,
        path: '/app/hr/offer-letter',
        component: lazy(() => import('./views/human-resource/OfferLetter'))
      },
      {
        exact: true,
        path: '/app/hr/employee-details',
        component: lazy(() => import('./views/human-resource/EmployeeDetails'))
      },
      {
        exact: true,
        path: '/app/hr/salary',
        component: lazy(() => import('./views/human-resource/Salary'))
      },
      {
        exact: true,
        path: '/full-calendar',
        component: lazy(() => import('./views/extensions/FullEventCalendar'))
      },

      /** quotation department */
      {
        exact: true,
        path: '/app/quotation/view',
        component: lazy(() => import('./views/quotation/Quotation/'))
      },
      {
        exact: true,
        path: '/app/quotation/add/:lead_id',
        component: lazy(() => import('./views/quotation/Quotation/MakeQuotation'))
      },
      /** employee common section */
      {
        exact: true,
        path: '/app/employee/attandance',
        component: lazy(() => import('./views/employee-common-pages/Attandance'))
      },
      {
        exact: true,
        path: '/app/employee/timesheet',
        component: lazy(() => import('./views/employee-common-pages/Timesheet/Timesheet'))
      },
      {
        exact: true,
        path: '/app/employee/employee-overtime',
        component: lazy(() => import('./views/employee-common-pages/Timesheet/Overtime'))
      },

      {
        exact: true,
        path: '/app/employee/goal-performance',
        component: lazy(() => import('./views/employee-common-pages/Appraisal/Goal'))
      },
      {
        exact: true,
        path: '/app/employee/employee-suggestion',
        component: lazy(() => import('./views/employee-common-pages/Appraisal/Suggestion'))
      },
      {
        exact: true,
        path: '/app/employee/compliance/nda',
        component: lazy(() => import('./views/employee-common-pages/Compliance/NDA'))
      },
      {
        exact: true,
        path: '/app/employee/compliance/conflict-of-interest',
        component: lazy(() => import('./views/employee-common-pages/Compliance/ConflictOfInterest'))
      },
      {
        exact: true,
        path: '/app/employee/joiningkit',
        component: lazy(() => import('./views/employee-common-pages/JoiningKit'))
      },
      {
        exact: true,
        path: '/app/employee/leave-request',
        component: lazy(() => import('./views/employee-common-pages/LeaveRequest'))
      },
      {
        exact: true,
        path: '/app/employee/resignation',
        component: lazy(() => import('./views/employee-common-pages/Resignation'))
      },
      {
        exact: true,
        path: '/app/employee/profile',
        component: lazy(() => import('./views/employee-common-pages/MyProfile'))
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={localStorage.getItem('isUserLogged') != null ? '/app/' + JSON.parse(localStorage.getItem('isUserLogged')).user.role + '/homes' : BASE_URL} />
      }
    ]
  },

  /** website frontend routes start */
  {
    exact: true,
    path: '*',
    layout: WebsiteFrontend,
    routes: [
      /** app (Header and Footer) */
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/website-frontend/Home/Home'))
      },
      /** Career List */
      {
        exact: true,
        path: '/careers',
        component: lazy(() => import('./views/website-frontend/Career/CareerList'))
      },
      /** Career List */
      {
        exact: true,
        path: '/careers/:detail',
        component: lazy(() => import('./views/website-frontend/Career/CareerDetail'))
      },
      /** FAQ */
      {
        exact: true,
        path: '/faq',
        component: lazy(() => import('./views/website-frontend/Faq/Faq'))
      },
      /** terms and condition */
      {
        exact: true,
        path: '/terms-and-conditions',
        component: lazy(() => import('./views/website-frontend/TermsAndCondition/TermsAndCondition'))
      },
      /** Privacy Policy */
      {
        exact: true,
        path: '/privacy-policy',
        component: lazy(() => import('./views/website-frontend/PrivacyPolicy/PrivacyPolicy'))
      },
      /** Disclaimer */
      {
        exact: true,
        path: '/disclaimer',
        component: lazy(() => import('./views/website-frontend/Disclaimer/Disclaimer'))
      },
      /** renolit */
      {
        exact: true,
        path: '/renolit',
        component: lazy(() => import('./views/website-frontend/Renolit/RenolitMainPage'))
      },
      /** renolit category */
      {
        exact: true,
        path: '/renolit/:category',
        component: lazy(() => import('./views/website-frontend/Renolit/RenolitCategory'))
      },
      /** renolit detail */
      {
        exact: true,
        path: '/renolit/:category/:detail',
        component: lazy(() => import('./views/website-frontend/Renolit/RenolitDetailPage'))
      },
      /** service */
      {
        exact: true,
        path: '/services',
        component: lazy(() => import('./views/website-frontend/Service/ServiceMainPage'))
      },
      /** service detail */
      {
        exact: true,
        path: '/services/:detail',
        component: lazy(() => import('./views/website-frontend/Service/ServiceDetailPage'))
      },
      /** project */
      {
        exact: true,
        path: '/swimming-pool-projects',
        component: lazy(() => import('./views/website-frontend/Project/ProjectMainPage'))
      },
      /** project detail */
      {
        exact: true,
        path: '/swimming-pool-projects/:detail',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/in-ground-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/lap-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/leisure-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/above-ground-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/olympic-size-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/infinity-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/podium-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/semi-olympic-size-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/overflow-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/skimmer-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/bio-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/water-park-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      {
        exact: true,
        path: '/swimming-pool-projects/acrylic-pools',
        component: lazy(() => import('./views/website-frontend/Project/ProjectDetailPage'))
      },
      /** product */
      {
        exact: true,
        path: '/products',
        component: lazy(() => import('./views/website-frontend/Product/ProductMainPage'))
      },
      /** product category */
      {
        exact: true,
        path: '/products/:category',
        component: lazy(() => import('./views/website-frontend/Product/ProductCategory'))
      },
      /** product sub category */
      {
        exact: true,
        path: '/products/:category/:subCategory',
        component: lazy(() => import('./views/website-frontend/Product/ProductSubCategory'))
      },
      /** product detail */
      {
        exact: true,
        path: '/products/:category/:subCategory/:product',
        component: lazy(() => import('./views/website-frontend/Product/ProductDetailPage'))
      },
      /** product detail temporary */
      // {
      //   exact: true,
      //   path: '/products/hot-tub',
      //   component: lazy(() => import('./views/website-frontend/Product/HotTubs'))
      // },
      // {
      //   exact: true,
      //   path: '/products/heat-pump',
      //   component: lazy(() => import('./views/website-frontend/Product/HeatPumps'))
      // },
      /** blog */
      {
        exact: true,
        path: '/blog',
        component: lazy(() => import('./views/website-frontend/Blog/BlogList'))
      },
      /** blog category */
      {
        exact: true,
        path: '/blog/:detail',
        component: lazy(() => import('./views/website-frontend/Blog/BlogDetail'))
      },
      /** newsAndUpdate */
      {
        exact: true,
        path: '/news-and-update',
        component: lazy(() => import('./views/website-frontend/NewsAndUpdate/NewsAndUpdateList'))
      },
      /** NewsAndUpdate category */
      {
        exact: true,
        path: '/news-and-update/:detail',
        component: lazy(() => import('./views/website-frontend/NewsAndUpdate/NewsAndUpdateDetail'))
      },
      /** contact */
      {
        exact: true,
        path: '/contact',
        component: lazy(() => import('./views/website-frontend/Contact/Contact'))
      },
      /** aboutUs */
      {
        exact: true,
        path: '/about-us',
        component: lazy(() => import('./views/website-frontend/AboutUs/AboutUs'))
      },
      /** WhySwimwell */
      {
        exact: true,
        path: '/why-swimwell',
        component: lazy(() => import('./views/website-frontend/WhySwimwell/WhySwimwell'))
      },
      /** Gallery */
      {
        exact: true,
        path: '/swimming-pool-project-gallery',
        component: lazy(() => import('./views/website-frontend/Gallery/Gallery'))
      },
      /** thankYou */
      {
        exact: true,
        path: '/thank-you',
        component: lazy(() => import('./views/website-frontend/ThankYou/ThankYou'))
      },
      /** random url */
      {
        exact: true,
        component: lazy(() => import('./views/website-frontend/Random/Random'))
      },
    ]
  },

  /** website frontend routes end */
];

export default routes;
